import { Component } from 'react';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';

export type OperationalEventProps = {
	id: string;
	action: string;
	actionSubject: string;
	source: string;
	containerId?: string;
	containerType?: string;
	attributes?: object;
};

export const OperationalEvent = withAnalyticsEvents()(
	class OperationalEvent extends Component<OperationalEventProps & WithAnalyticsEventsProps> {
		componentDidMount() {
			this.sendOperationalEvent();
		}

		shouldComponentUpdate(nextProps: OperationalEventProps & WithAnalyticsEventsProps) {
			return (
				nextProps.id !== this.props.id ||
				nextProps.action !== this.props.action ||
				nextProps.actionSubject !== this.props.actionSubject
			);
		}

		componentDidUpdate() {
			this.sendOperationalEvent();
		}

		sendOperationalEvent() {
			const {
				createAnalyticsEvent,
				action,
				actionSubject,
				source,
				attributes,
				containerType,
				containerId,
			} = this.props;

			createAnalyticsEvent!({
				type: 'sendOperationalEvent',
				data: {
					action,
					actionSubject,
					source,
					containerType,
					containerId,
					attributes: attributes || null,
				},
			}).fire();
		}

		render() {
			return null;
		}
	},
);
